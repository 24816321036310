<template>
    <vx-card class="user-profile-page"
             :class="{'main-box top-zero-radius md:w-1/2': !termId, 'no-box-shadow': termId > 0}"
             v-if="term && Object.keys(term).length > 0">

        <profile-main-box :profile="getTermProfile"
                          :actionsList="actionsList"
                          @showTermPrice="setTermPricePromptStatus = true"
                          @setUserMaxDebt="setUserMaxDebtPromptStatus = true"/>


        <!-- show term price prompt -->
        <vs-prompt
                class="very-big-prompt p-0"
                :buttons-hidden="true"
                title=""
                :active.sync="setTermPricePromptStatus"
                @close="setTermPricePromptStatus = true">

            <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
                <vs-row>
                    <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                        <div v-if="checkUserPermissions('term.update')" @click="handleClick('editTimePrice')">
                            <custom-icon icon="EDIT" color="warning"/>
                        </div>
                    </vs-col>

                    <vs-spacer/>

                    <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                        {{ $t('terms.price.dynamicTitle', {name: getTermProfile.name}) }}
                    </vs-col>

                    <vs-spacer/>

                    <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
                        <div class="mr-2" @click="printPage">
                            <custom-icon icon="PRINT"/>
                        </div>
                        <div class="ml-2" @click="setTermPricePromptStatus = false">
                            <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                        </div>
                    </vs-col>
                </vs-row>
            </div>

            <div class="prompt-content">
                <template>
                    <keep-alive>
                        <terms-price-list :term-id="termId" />
                    </keep-alive>
                </template>
            </div>

        </vs-prompt>
    </vx-card>
</template>

<script>
    import {getTerm} from '@/http/requests/terms'
    import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
    import {addComma, checkUserPermissions} from '@/assets/js/functions'
    import CustomIcon from '../../../../components/customIcon/customIcon'
    import {getAvatarUrl} from "../../../../assets/js/functions"
    import TermsPriceList from "../price/list/termsPriceList";

    export default {
        name: 'TermProfile',
        components: {TermsPriceList, CustomIcon, ProfileMainBox},
        props: {
            termId: 0
        },
        metaInfo () {
            return {
                title: this.term ? this.$t('terms.profile.dynamicTitle', {name: this.term.general.name.value}) : this.$t('terms.profile.title')
            }
        },
        data () {
            return {
                setTermPricePromptStatus: false,
                showUserTransactionsPromptStatus: false,
                actions: {
                    toolbar: [
                        {
                            id: {name: 'editUser', params: {id: this.$route.params.id}},
                            type: 'link',
                            icon: 'icon-edit-2',
                            iconPack: 'feather',
                            color: 'warning'
                        }
                    ],
                    leftToolbar: [
                        {
                            id: {name: 'users'},
                            type: 'link',
                            icon: 'icon-chevron-left',
                            iconPack: 'feather'
                        }
                    ]
                },
                term: null,
                actionsList: [
                    {
                        event: 'showTermPrice',
                        // params: {id: this.termId || this.$route.params.id},
                        header: '',
                        // preview: {
                        //     value: addComma(25000) + ' تومان',
                        //     color: 'success'
                        // },
                        permission: 'term.update',
                        i18n: 'terms.profile.actions.price',
                        icon: 'icon-dollar-sign',
                        iconPack: 'feather'
                    },
                ]
            }
        },
        created () {
            //check permissions
            if (!checkUserPermissions('user.update')) {
                delete this.actions.toolbar
            }

            if (!this.termId) {
                setTimeout(() => {
                    this.$store.dispatch('updateNavbarActions', this.actions)
                    this.$store.dispatch('updateContentNavbarStyle', 'sticky')
                    this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
                }, 50)
            }

            this.getTerm()
        },
        computed: {
            getTermProfile () {
                const term = {
                    avatar: this.term.avatar ? getAvatarUrl(this.term.avatar) : '',
                    name: `${this.term.general.name.value}`,
                    general: this.term.general,
                    asiderAvatar: [
                        {
                            content: `${this.term.general.name.value}`
                        },
                        {
                            content: `${this.term.general.startTime} ${this.$t('terms.profile.asiderAvatar.until')} ${this.term.general.endTime}`,
                            preContent: this.$t('terms.profile.asiderAvatar.term')
                        }
                    ],
                    details: [],
                    importantData: []
                }

                return term
            }
        },
        methods: {
          checkUserPermissions(permission) {
            return checkUserPermissions(permission)
          },
            getTerm () {

                getTerm(this.termId || this.$route.params.id).then(res => {
                    const term = res.data.data
                    this.term = {
                        general: {
                            name: {
                                value: term.name,
                                isInvalid: ''
                            },
                            startTime: term.start_time || '',
                            endTime: term.end_time || ''
                        },
                        avatar: term.avatar || ''
                    }

                    if (!this.term.access) {
                        this.actionsList.splice(2, 1)
                    }

                    if (!this.termId) {
                        this.$store.dispatch('setPageTitle', this.$t('terms.profile.dynamicTitle', {name: this.term.general.name.value}))
                    }
                })
            },
            handleClick (id) {
                document.getElementById(id).click()
            },
            printPage () {
                window.print()
            }
        }
    }
</script>

<style lang="scss">
    .user-profile-page {

        .vx-card__body {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    @media print {
        .con-vs-dialog.very-big-prompt {
            .vs-dialog {
                height: 99% !important;
                width: 99% !important;
                max-width: 99% !important;
            }
        }
    }

    @page {
        margin: 0.5mm;
    }
</style>
