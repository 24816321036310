import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
    let queries = []

    if (page > 1) queries.push(`page=${page}`)

    if (filters.length) queries = queries.concat(...filters)

    if (sorts.length) queries = queries.concat(...sorts)

    return queries
}

export function getTerms (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/terms${queries}`,
        method: 'get'
    })
}

export function getTerm (id) {
    return axios({
        url: `v1/admin/terms/${id}`,
        method: 'get'
    })
}

export function getTermsTrash (page = 1, filters = [], sorts = []) {

    let queries = makeQuery(page, filters, sorts)

    queries = queries.length > 0 ? `&${  queries.join('&')}` : ''

    return axios({
        url: `v1/admin/terms?trashed=true${queries}`,
        method: 'get'
    })
}

export function insertTerm (payment) {
    return axios({
        url: 'v1/admin/terms',
        data: payment,
        method: 'post'
    })
}

export function editTerm (id, payment) {
    return axios.patch(`v1/admin/terms/${id}`, payment)
}

export function deleteTerm (id, force = false) {
    return axios({
        url: `v1/admin/terms/${id}${force ? '/force' : ''}`,
        method: 'delete'
    })
}

export function restoreTerm (id) {
    return axios({
        url: `v1/admin/terms/${id}/restore`,
        method: 'put'
    })
}
