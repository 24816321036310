<template>
  <div class="edit-term-prices">

    <draggable-dynamic-table ref="editTermsPriceTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="showTerm"
                             v-model="data"/>


    <button id="editTermPriceBTN" v-show="false" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getTimePrices, editTimePrices} from '@/http/requests/timePrices'
import { addComma } from '../../../../../assets/js/functions'
import InsertTerm from '../../insert/insertTerm'
import EditTerm from '../../edit/editTerm'
import TermProfile from '../../profile/termProfile'
import CustomIcon from '../../../../../components/customIcon/customIcon'

export default {
  name: 'EditTermPrice',
  metaInfo () {
    return {
      title: this.$t('terms.price.edit.title')
    }
  },
  components: {
    CustomIcon,
    TermProfile,
    EditTerm,
    InsertTerm,
    DraggableDynamicTable
  },
  props: {
    termId: 0
  },
  data () {
    return {
      requestSent: false,
      loadingTimer: 0,
      options: {
        id: 'editTermsPriceTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'day_7',
          i18n: 'terms.price.table.header.friday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_6',
          i18n: 'terms.price.table.header.thursday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_5',
          i18n: 'terms.price.table.header.wednesday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_4',
          i18n: 'terms.price.table.header.tuesday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_3',
          i18n: 'terms.price.table.header.monday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_2',
          i18n: 'terms.price.table.header.sunday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_1',
          i18n: 'terms.price.table.header.saturday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'courtName',
          i18n: 'terms.price.table.header.courtName',
          width: '150px',
          minWidth: 150,
          align: 'center',
          // locked: true,
          // sortable: true,
          // footer: {
            /*type: 'auto-counter'*/
          // }
        }
      ],
      data: [],
      filters: [`term=${this.termId}`],
      sorts: ['order[0]=court_id,asc'],
      selectedUser: {},
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewCourt',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'user.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getTerms()
  },
  methods: {
    getTerms () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.editTermsPriceTable && this.data.length === 0) {
              this.$refs.editTermsPriceTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.editTermsPriceTable && this.data.length > 0) {
              this.$refs.editTermsPriceTable.loadMoreStatus = 'Loading'
            }

            getTimePrices(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              let data = {}
              res.data.data.forEach((term) => {
                if (term.court) {
                  if (!data[`court_${term.court.id}`]) {
                    data[`court_${term.court.id}`] = {}
                  }
                  data[`court_${term.court.id}`]['courtName'] = term.court.name || ''
                  data[`court_${term.court.id}`]['courtId'] = term.court.id
                  data[`court_${term.court.id}`]['flag'] = 'NEW'
                  data[`court_${term.court.id}`][`day_${term.week_day}`] = addComma(term.price)
                }
              })
              const dataArray = Object.values(data)
              setTimeout(() => {
                dataArray.forEach((item) => {
                  this.data.push(item)
                })
              }, 100)

              // this.page = res.data.pagination.current_page + 1
              // if (res.data.pagination.current_page === 1) {
                // const row_index = this.columnsLabel.map((e) => {
                //   return e.field
                // }).indexOf('row')
                // this.columnsLabel[row_index].footer.value = res.data.pagination.total
              // }

              if (this.$refs.editTermsPriceTable) this.$refs.editTermsPriceTable.loadMoreStatus = ''

              this.requestSent = false
            })
              .catch((error) => {
                if (this.$refs.editTermsPriceTable) this.$refs.editTermsPriceTable.loadMoreStatus = 'Danger'

                this.requestSent = false
              })
          } else {

            this.requestSent = false
          }
        }, 400)
      }
    },
    showTerm (user) {
      // this.selectedUser = user
      // this.showTermPromptStatus = true
    },
    setFilters (filters) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'accessGroup':
          if (filters[key].search !== '') filters_list.push(`role=${  filters[key].search}`)
          break

        case 'gender':
          if (filters[key].search.value > 0) filters_list.push(`gender=${  filters[key].search.value }`)
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.push(`phone_number=${  filters[key].search}`)
          break

        case 'company':
          if (filters[key].search !== '') filters_list.push(`company=${  filters[key].search}`)
          break

        case 'name':
          if (filters[key].search !== '') filters_list.push(`full_name=${  filters[key].search}`)
          break

        case 'registryDate':
          if (filters[key].search !== '') filters_list.push(`created_at=${  filters[key].search}`)
          break
        }
      })
      filters_list.push(`term=${this.termId}`)

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getTerms()
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

          /*case "accessGroup":
            sorts_list.push("order[0]=role," + (columns[key] ? 'desc' : 'asc'))
            break;*/

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'group':
          sorts_list.push(`order[0]=group,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=court_id,asc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getTerms()
    },

    sendData () {
      let payload = []

      this.data.forEach((court) => {
        if (court.flag === 'UPDATED') {
          for (let i = 1; i <= 7; i++) {
            payload.push({
              week_day: i,
              term_id: this.termId,
              court_id: court.courtId,
              price: court[`day_${i}`] ? court[`day_${i}`].replaceAll(',', '') : '0'
            })
          }
        }
      })

      if (payload.length > 0) {
        editTimePrices({prices: payload}).then((response) => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('terms.price.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')

        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': '',
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('terms.price.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    },

    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-term-prices {
    height: 100%;
  }
</style>
